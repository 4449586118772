import React, {useState, useEffect} from 'react'
import { originGroupsArray } from './models/OriginGroups'
import DropdownOption from 'components/common/dropdown/DropdownOption.js'
import Dropdown       from 'components/common/dropdown/Dropdown.js'
import { statsOrigin } from 'components/services/statistics.js'
import { sortObjectArray } from 'components/utils/helper'
import getBroadcastingYears from 'components/services/getBroadcastingYears.js'
// import is needed
import { Chart as ChartJS } from 'chart.js/auto'
import { Bar }              from 'react-chartjs-2'

function LoadingGraphData() {
    return (
        <div>
        <p>Loading data... </p>
        </div>
    )
}

const GraphControls = (
    {broadcastingYears, selectedBroadcastingYear, onBroadcastingYearChange, originGroups, selectedOriginGroup, onOriginGroupChange}) => {
    return (
        <div className="flex flex-col md:flex-row justify-between my-4">
            <div className="my-4 md:my-0">
                <Dropdown label='Uitzendjaar' options={broadcastingYears} selected={selectedBroadcastingYear} onSelectedChange={onBroadcastingYearChange} />
            </div>
            <div className="my-4 md:my-0">
                <Dropdown label='Land selectie' options={originGroups} selected={selectedOriginGroup} onSelectedChange={onOriginGroupChange} />
            </div>
        </div>
    )
}

const TotalParagraph = ({graphData}) => {
    var total = 0;
    graphData.forEach(element => {total = total + element.count});
    return (
        <p className="text-white text-right">Totaal aantal platen in dit diagram: <span className="font-bold text-red-600">{graphData.reduce((a, b) => +a + +b.count, 0)}</span></p>
    )
}

const BarChart= ({graphData}) => {
    const labels = graphData.map(function(element) { return String(element.countryName) })
    const data = graphData.map(function(element) { return element.count })
    const options = {
        indexAxis: 'y',
        elements: {
            bar: {
                borderWidth: 2,
            },
        },
        responsive: true,
        plugins: {
            legend: {
                display: false
            },
            title: {
                display: false,
            },
        },
        scales: {
            x: {
                ticks: {
                    precision: 0,
                    color: "white",
                }
            },
            y: { 
                ticks: {
                    color: "white",
                }
            }
        }
      };
    const state = {
        labels: labels,
        datasets: [
          {
            backgroundColor: 'rgba(96,165,250,1)',
            borderColor: 'rgba(255,255,255,1)',
            data: data
          }
        ]
    }

    return (
        <div className="py-4">
            <Bar data={state} options={options} />
        </div>
    )
}

const StatsRecordsOrigin = () => {
    const broadcastingYears = getBroadcastingYears().map(year => new DropdownOption(year, year)).sort(sortObjectArray('value', 'desc'));
    const originGroups = originGroupsArray.map(e => new DropdownOption(e.description, e.id));

    const [isLoading, setIsLoading] = useState(false);
    const [broadcastingYear, setBroadcastingYear] = useState(broadcastingYears[0].value);
    const [originGroupId, setOriginGroupId] = useState(originGroups[1].value);
    const [graphData, setGraphData] = useState(null);
    const [errorMessage, setErrorMessage] = useState(null);

    function handleBroadcastingYearChange(dropDownOption) {
        setBroadcastingYear(dropDownOption.value);
    }

    const handleOriginGroupChange = (dropDownOption) => {
        setOriginGroupId(dropDownOption.value);
    }

    useEffect(() => {
        async function fetchStatsOrigin() {
            setIsLoading(true);
            try {
                const result = await statsOrigin(broadcastingYear, originGroupId);
                // Data is returned as [{countryId;countryName;count}, ...]
                setGraphData(result);
                setIsLoading(false);
            } catch(error) {
                setIsLoading(false);
                setErrorMessage("Something went wrong... apologies");
            }
        }
        
        fetchStatsOrigin();
    }, [broadcastingYear, originGroupId]); // Only rerun when broadcastingYear or originGroup has changed

    return (
        <div className="flex flex-col w-full">
        <p>{errorMessage}</p>
        <h1 className="font-medium text-3xl text-center text-gray-100">Herkomst van platen</h1>
        <p className="text-white">De onderstaande grafiek geeft voor het gekozen uitzendjaar het aantal platen weer voor de geselecteerde herkomst. Selecteer een ander uitzendjaar en herkomst om de grafiek te vernieuwen.</p>
        {graphData == null ? <LoadingGraphData /> : (
            <div>
                <GraphControls 
                    broadcastingYears={broadcastingYears}
                    selectedBroadcastingYear={broadcastingYear}
                    onBroadcastingYearChange={handleBroadcastingYearChange}
                    originGroups={originGroups}
                    selectedOriginGroup={originGroupId}
                    onOriginGroupChange={handleOriginGroupChange} />
                <BarChart graphData={graphData} />
                <TotalParagraph graphData={graphData} />
            </div>
            )}
        </div>
    );
}

export default StatsRecordsOrigin;
