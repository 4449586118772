// Componnt to present both barcharts for insight per decade
import React from 'react'
import RecordsPerDecade from "./statsForDecade/RecordsPerDecade";
import RecordsInDecade from "./statsForDecade/RecordsInDecade.js";

const StatsRecordsForDecades = () => {
    return (
        <div className="flex flex-col w-full mb-12">
            <RecordsPerDecade />
            <RecordsInDecade />
        </div>
    );
}

export default StatsRecordsForDecades;
