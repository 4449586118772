import React, { useState } from 'react';
import { classList, artistImageUrl } from 'components/utils/helper'

function RecordPosition(props) {
    const position = props.position.position == null ? "-" : props.position.position;
    return (
        <div className="lastweek record_button">
            <p><span>{position}</span></p>
            <h4>In {props.position.year}</h4>
        </div>
    );
}

function RecordAudioButton(props) {
    const songtitle = props.songtitle;
    const stylingClasses = classList({
        "record_button": true,
        "preview": true,
        "play_state": props.isPlaying
    });
    
    if (songtitle.fragment) {
        return <a href='#' className={stylingClasses} data-audio={songtitle.fragment} data-record-id={songtitle.titelId} onClick={() => props.onAudioClick(songtitle.titleId)}>Fragment</a>;
    } else {
        return (null);
    }
}

function RecordVideoButton(props) {
    const songtitle = props.songtitle;
    const videoSearchArg = songtitle.artistName + " " + songtitle.titleName;
    return <a href='#' className="record_button playvideo" onClick={() => props.onVideoClick(videoSearchArg)}>YouTube</a>;
}

function RecordContent(props) {
    const songtitle = props.songtitle;
    const fullTitle = songtitle.artistName.concat(" - ").concat(songtitle.titleName);
    var sortedPositions = songtitle.positions
    sortedPositions.sort((lhs, rhs) => rhs.year - lhs.year);
    
    function getBackgroundStyling() {
        const backgroundImage = props.isSelected ? "bg_labels_active.png" : "bg_labels.png"
        const result = {backgroundImage : "url('" + String(require("assets/images/" + backgroundImage)) + "')"};
        return result;
    }
    
    return (
        <div className="record_content">
            <div className="record_ribbons"></div>
            <div className="record_interact">
                <div className="record_position">
                    <p><span>{sortedPositions[0].position}</span></p>
                </div>
                <div className="record_label clearfix" title={fullTitle} style={getBackgroundStyling()}>
                    <h2 className="title"><span>{songtitle.titleName}</span></h2>
                    <h3 className="artist"><span>{songtitle.artistName}</span></h3>
                </div>
                <div className="record_buttons">
                    <RecordPosition position={sortedPositions[1]} />
                    <RecordPosition position={sortedPositions[2]} />
                    <RecordPosition position={sortedPositions[3]} />
                    <RecordAudioButton songtitle={songtitle} isPlaying={props.isPlaying} onAudioClick={props.onAudioClick} />
                    <RecordVideoButton songtitle={songtitle} onVideoClick={props.onVideoClick} />
                </div>
                <p className="band_label label_button"><span>{songtitle.country}</span></p>
            </div>
        </div>
    );
    
}

function RecordCover(props) {
    const imageSrc = artistImageUrl(props.imageName);
    
    return (
        <div className="record_cover">
            <img src={String(imageSrc)} alt={""} />
        </div>
    );
}

function RecordWrapper(props) {
    const songtitle = props.songtitle;
    const styling = {
        animationName: props.hasFocus ? "animateScrollUp" : "animateScrollDown"
    };
    
    return (
        <div className="record_wrapper" style={styling}>
            <RecordContent songtitle={songtitle} isSelected={props.isSelected} isPlaying={props.isPlaying} onAudioClick={props.onAudioClick} onVideoClick={props.onVideoClick} />
            <RecordCover imageName={songtitle.photo} />
        </div>
    );
} 

export function ScrollableTitle(props) {
    const [hasFocus, setHasFocus] = useState(false);
    const songtitle = props.songtitle;
    const fullTitle = songtitle.artistName.concat(" - ").concat(songtitle.titleName);

    function titleEntered() {
        setHasFocus(true);
    }

    function titleLeft() {
        setHasFocus(false);
    }

    const styling = {
        zIndex: 50
    };

    return (
        <article data-record-id={songtitle.titelId} data-video-id={fullTitle} className="record_case" style={styling} onMouseEnter={titleEntered} onMouseLeave={titleLeft}>
            <RecordWrapper 
                songtitle={songtitle}
                isSelected={props.isSelected}
                isPlaying={props.isPlaying}
                onAudioClick={props.onAudioClick}
                onVideoClick={props.onVideoClick}
                hasFocus={hasFocus} />
        </article>
    );
}
