import React from 'react'

const Footer = () => {
  return (
    <div className="mt-8 w-full bg-gray-800 border-t-2 border-black">
      <div className="w-2/3 lg:w-1/2 mx-auto py-8">
      <p className="text-white text-center text-xs">Deze website is geen commerciële website en dient niet als bron van inkomsten.
Al het fotomateriaal op deze website is afkomstig van het internet en niet aangeleverd door derden.
Mocht u als rechthebbende hierdoor mogelijk schade ondervinden, maak dit dan via het contactformulier bekend.
Het materiaal zal dan z.s.m. worden vervangen.</p>
      </div>
    </div>
  )
};

export default Footer;
