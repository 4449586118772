// Statistics component to display a barchart per broadcast year and per decade.

import React, {useState, useEffect, useCallback} from 'react'
import Dropdown       from 'components/common/dropdown/Dropdown.js'
import DropdownOption from 'components/common/dropdown/DropdownOption.js'
import { statsTitlesPerDecade } from 'components/services/statistics.js'
import { sortObjectArray } from 'components/utils/helper.js'
import getBroadcastingYears from 'components/services/getBroadcastingYears.js'
// import is needed
import { Chart as ChartJS } from 'chart.js/auto'
import { Bar }              from 'react-chartjs-2'

function LoadingGraphData() {
    return (
        <div>
        <p>Loading data... </p>
        </div>
    )
}

const GraphControls = ({broadcastingYears, selectedBroadcastingYear, onBroadcastingYearChange}) => {
    return (
        <div className="flex flex-col md:flex-row md:justify-between my-4">
            <div className="my-4 md:my-0">
                <Dropdown label='Uitzendjaar' options={broadcastingYears} selected={selectedBroadcastingYear} onSelectedChange={onBroadcastingYearChange} />
            </div>
        </div>
    )
}

const BarChart = ({graphData}) => {
    const labels = graphData.map(function(element) { return String(element.description) })
    const data = graphData.map(function(element) { return element.count })
    const options = {
        //indexAxis: 'y',
        elements: {
            bar: {
                borderWidth: 2,
            },
        },
        responsive: true,
        plugins: {
            legend: {
                display: false
            },
            title: {
                display: false,
            },
        },
        scales: {
            x: {
                ticks: {
                    precision: 0,
                    color: "white",
                }
            },
            y: { 
                ticks: {
                    color: "white",
                }
            }
        }
    };
    const state = {
        labels: labels,
        datasets: [
          {
            backgroundColor: 'rgba(96,165,250,1)',
            borderColor: 'rgba(240,240,240,1)',
            data: data
          }
        ]
    };

    return (
        <div className="py-4">
            <Bar data={state} options={options} />
        </div>
    )
}

const RecordsPerDecade = () => {

    const broadcastingYears = getBroadcastingYears().map(year => new DropdownOption(year, year)).sort(sortObjectArray('value', 'desc'));
    
    const [isLoading, setIsLoading] = useState(false);
    const [broadcastingYear, setBroadcastingYear] = useState(broadcastingYears[0].value);
    
    const [graphData, setGraphData] = useState(null);
    const [errorMessage, setErrorMessage] = useState(null);

    function handleBroadcastingYearChange(dropDownOption) {
        setBroadcastingYear(dropDownOption.value);
    }

    // useCallback solves the issue when two requests arre started from tthe same page.
    const fetchTitlesPerDecade = useCallback(async () => {
        try {
            setIsLoading(true);
            const result = await statsTitlesPerDecade(broadcastingYear);
            // Data is returned as [{description;count}, ...]
            setGraphData(result);
            setIsLoading(false);
        } catch(error) {
            setIsLoading(false);
            setErrorMessage("Something went wrong... apologies");
        }
    }, [broadcastingYear]); // Only rerun when broadcastingYear has changed

    useEffect(() => {
        fetchTitlesPerDecade();
    }, [fetchTitlesPerDecade]);
    
    return (
        <div className="flex flex-col w-full mb-12">
        <p>{errorMessage}</p>
        <p className="font-medium text-lg md:text-2xl text-center text-gray-100">Aantal platen per decennium</p>
        <p className="text-xs md:text-base text-white">De onderstaande grafiek geeft voor het gekozen uitzendjaar het aantal platen weer per decennium. Selecteer een ander uitzendjaar om de grafiek te vernieuwen.</p>
        {isLoading === true ? <LoadingGraphData /> : null}
        {graphData == null ? null : (
            <div>
                <GraphControls 
                    broadcastingYears={broadcastingYears}
                    selectedBroadcastingYear={broadcastingYear}
                    onBroadcastingYearChange={handleBroadcastingYearChange} />
                <BarChart graphData={graphData} />
            </div>
            )
        }
        </div>
    );
}

export default RecordsPerDecade;
