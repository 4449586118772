import React, {useState, useEffect} from 'react'
import { ImStatsDots } from 'react-icons/im'
import SongTitle from 'components/models/SongTitle'
import DropdownOption from 'components/common/dropdown/DropdownOption.js'
import Dropdown       from 'components/common/dropdown/Dropdown.js'
import LoadingIndicator from 'components/common/LoadingIndicator.js'
import SongtitleGraphOverlay from 'components/common/SongtitleGraphOverlay.js'
import { artistImageUrl, sortObjectArray } from 'components/utils/helper'
import getBroadcastingYears from 'components/services/getBroadcastingYears.js'
import { statsMovements } from 'components/services/statistics.js'
import { Movements } from './models/Movements.js'

function LoadingMovements() {
    return (
        <div>
        <p>Loading data... </p>
        </div>
    )
}

const ListPicture = ({image}) => {
    const styling = {backgroundImage : "url('" + artistImageUrl(image) + "')"};
    return (
        <div className="w-24 h-16 md:w-32 md:h-24 max-h-full bg-cover bg-center bg-no-repeat" style={styling} />
    );
}

const PositionDifference = ({songTitle}) => {

    const NewEntry = () => {
        const styling = {backgroundImage : "url('" + String(require("assets/images/new.png")) + "')"};
        return (
            <>
                <div className="mx-auto w-6 h-6 bg-cover bg-center bg-no-repeat" style={styling} />
            </>
        )
    }

    const ExistingEntry = ({songTitle}) => {
        const difference = parseInt(songTitle["positionPreviousYear"]) - parseInt(songTitle["position"]);
        var assetName = difference === 0 ? "minus.png" : (difference > 0 ? "arrow-up.png" : "arrow-down.png");
        const styling = {backgroundImage : "url('" + String(require("assets/images/"  + assetName)) + "')"};
        return (
            <>
                <div className="mx-auto w-4 h-4 bg-cover bg-center bg-no-repeat" style={styling} />
                {difference !== 0 ? <div className="text-center text-gray-500">{Math.abs(difference)}</div> : null }
            </>
        )
    }

    return (
        <div className="mr-2 md:mr-0 w-1/12 flex-col text-center">
          {songTitle["positionPreviousYear"] === null || isNaN(songTitle["positionPreviousYear"])
            ? <NewEntry />
            : <ExistingEntry songTitle={songTitle} />}
        </div>
    )
}

const MovementRowComponent = ({songTitle, onStatsClicked}) => {
    return (
        <div className="flex w-full h-16 md:h-24 items-center bg-gray-800 rounded-md mb-2">
            <div className="w-12 md:w-16 font-bold text-lg md:text-xl text-center text-gray-300">{songTitle.position}</div>
            <ListPicture image={songTitle.photo} />
            <div className="flex-1 flex flex-col overflow-hidden">
                <div className="pl-4 text-left text-gray-300 text-sm md:text-lg italic truncate">{songTitle.titleName}</div>
                <div className="pl-4 text-left text-gray-500 text-sm md:text-lg truncate">{songTitle.artistName}</div>
            </div>
            <div className="hidden md:block w-1/12 text-center text-gray-300 text-sm">{songTitle["year"]}</div>
            <PositionDifference songTitle={songTitle} />
            
            <div className="hidden md:block w-1/12 flex flex-col">
                <div className="text-center text-gray-300">{songTitle.positionPreviousYear}</div>
                <div className="text-center text-gray-600 text-sm">vorig jaar</div>
            </div>
            <div className="hidden md:block w-1/12 flex flex-col">
                <div className="text-center text-gray-300">{songTitle.topPosition}</div>
                <div className="text-center text-gray-600 text-sm">Top positie</div>
            </div>
            <div className="hidden md:block w-1/12 flex flex-col">
                <div className="text-center text-gray-300">{songTitle.numEditions}</div>
                <div className="text-center text-gray-600 text-sm"># edities</div>
            </div>
            <div className="hidden md:block w-12">
                <button className="text-gray-300 inline-flex items-center" onClick={() => onStatsClicked(songTitle.titleId)}>
                    <ImStatsDots className="w-6 h-6" />                    
                </button>
            </div>
        </div>
    )
}

const MovementsComponent = ({isLoading, songTitles, onStatsClicked}) => {
    return (
        <div className="relative">
        
        {songTitles.map(function(songTitle, i) {
            return (
                <MovementRowComponent key={i} songTitle={songTitle} onStatsClicked={onStatsClicked} /> 
            );
        })}
        <LoadingIndicator isLoading={isLoading} />
        </div>
    );
}

const MovementsHeader = () => {
    return (
        <div className="flex w-full h-12 items-center">
            <div className="w-12 md:w-16 text-xs text-gray-600 text-center">Positie</div>
            <div className="w-24 md:w-32" />
            <div className="flex-1 text-xs text-gray-600 text-left pl-4">Titel &amp; artiest</div>
            <div className="w-1/12 text-xs text-gray-600 text-center hidden md:block">Jaar</div>
            <div className="w-1/12 text-xs text-gray-600 text-center hidden md:block">Verschuiving</div>
            <div className="w-1/12 text-xs text-gray-600 text-center hidden md:block">Vorig jaar</div>
            <div className="w-1/12 text-xs text-gray-600 text-center hidden md:block">Top positie</div>
            <div className="w-1/12 text-xs text-gray-600 text-center hidden md:block"># Edities</div>
            <div className="w-12 text-xs text-gray-600 text-left"></div>
        </div>
    )
}

//********************************************************************** */

const MovementControls = ({broadcastingYears, selectedBroadcastingYear, onBroadcastingYearChange,
    movements,selectedMovement,onMovementChange}) => {
    return (
        <div className="flex flex-col md:flex-row md:justify-between my-4">
            <div className="my-4 md:my-0">
                <Dropdown label='Uitzendjaar' options={broadcastingYears} selected={selectedBroadcastingYear} onSelectedChange={onBroadcastingYearChange} />
            </div>
            <div className="my-4 md:my-0">
                <Dropdown label='Selectie' options={movements} selected={selectedMovement} onSelectedChange={onMovementChange} />
            </div>
        </div>
    )
}

const StatsRecordsMovement = () => {
    const broadcastingYears = getBroadcastingYears(2000).map(year => new DropdownOption(year, year)).sort(sortObjectArray('value', 'desc'));
    const movementDropdownOptions = Movements.map(element => new DropdownOption(element.title, element.id)); // label, value
    const [selectedMovement, setSelectedMovement] = useState(movementDropdownOptions[0].value);
    const [isLoading, setIsLoading] = useState(false);
    const [broadcastingYear, setBroadcastingYear] = useState(broadcastingYears[0].value);
    const [songTitles, setSongTitles] = useState(null);
    const [errorMessage, setErrorMessage] = useState(null);

    // State and methods for displaying the graph overlay
    const [titleId, setTitleId] = useState(null);
    const [isOverlayPresented, setIsOverlayPresented] = useState(false);

    const onStatsClicked = (titleId) => {
        setTitleId(titleId);
        setIsOverlayPresented(true);
    }

    function handleOverlayDismissed() {
        setIsOverlayPresented(false);
    }
    
    function handleBroadcastingYearChange(dropDownOption) {
        setBroadcastingYear(dropDownOption.value);
    }
    // Can also be defined as a const
    /*const handleBroadcastingYearChange = (dropDownOption) => {
        setBroadcastingYear(dropDownOption.value);
    }*/
    const handleMovementChange = (dropDownOption) => {
        setSelectedMovement(dropDownOption.value);
    }

    useEffect(() => {
        // Method to get the data
        async function fetchRecordMovements() {
            try {
                const movement = Movements.find(e => e.id === selectedMovement);
                const result = await statsMovements(broadcastingYear, movement.lowerlimit, movement.upperlimit);

                // Map data to a SongTitle object, containing properties 'position' and 'positionPreviousYear'.
                const songTitles = result.map(songTitle => new SongTitle(songTitle, broadcastingYear));
                setSongTitles(songTitles);
                setIsLoading(false);
            } catch(error) {
                console.log(error);
                setIsLoading(false);
                setErrorMessage("Something went wrong... apologies");
            }
        }

        setIsLoading(true);
        fetchRecordMovements();
    }, [broadcastingYear, selectedMovement]); // Only rerun when broadcastingYear or selectedMovement has changed
    
    return (
        <div className="relative w-full my-8">
            <p className="font-medium text-lg md:text-2xl text-center text-gray-100">Stijgers en dalers</p>
            <p className="text-xs md:text-base text-white">Selecteer een uitzendjaar en kies voor het tonen van de platen die zijn gestegen of zijn gedaald.</p>
            <MovementControls
                broadcastingYears={broadcastingYears}
                selectedBroadcastingYear={broadcastingYear}
                onBroadcastingYearChange={handleBroadcastingYearChange}
                movements={movementDropdownOptions}
                selectedMovement={selectedMovement}
                onMovementChange={handleMovementChange} />
                {errorMessage === null ? null : <p>{errorMessage}</p>}
            <MovementsHeader />
            {songTitles === null ? <LoadingMovements /> : (
                <MovementsComponent isLoading={isLoading} songTitles={songTitles} onStatsClicked={onStatsClicked} />
            )}
            <SongtitleGraphOverlay titleId={titleId} isOverlayPresented={isOverlayPresented} onDismiss={handleOverlayDismissed} />
        </div>
    );
}

export default StatsRecordsMovement;
