import React, { useState, useEffect} from 'react'
import SearchTabbar from './SearchTabbar'
import SearchResultContainer from './SearchResultContainer'
import SearchResult from './models/SearchResult'
import ErrorComponent from 'components/common/ErrorComponent'
import { search } from 'components/services/search'

const SearchResultText = ({text}) => {
    return (
        <div className="w-full bg-gray-200 text-blue-800 font-medium rounded-lg p-2">{text}</div>
    )
}

const Search = () => {

    const [searchObject, setSearchObject] = useState(null);
    const [searchResultText, setSearchResultText] = useState("");
    const [searchResult, setSearchResult] = useState(null);
    const [isSearching, setIsSearching] = useState(false);
    const [errorObject, setErrorObject] = useState(null);

    const onTabClicked = () => {
        setSearchObject(null);
        setSearchResultText("");
        setSearchResult(null);
    }

    const startSearch = (searchType, arg) => {
        setSearchObject({"searchType": searchType, "arg": arg})
    }

    useEffect(() => {
        async function performSearch() {
            try {
                if (searchObject === null) {
                    return
                }
                setIsSearching(true);
                console.log("Start Search with searchType " + searchObject.searchType + " and arg " + searchObject.arg);
                const result = await search(searchObject.searchType, searchObject.arg);
                var resultArray = [];
                if (result instanceof Array) {
                    if (result.length === 0) {
                        setSearchResultText("Er zijn geen titels gevonden, die voldoen aan uw zoekterm.")
                    } else {
                        if (result.length === 1) {
                            setSearchResultText("Er is " + result.length + " titel gevonden, die voldoet aan uw zoekterm.")
                        } else {
                            setSearchResultText("Er zijn " + result.length + " titels gevonden, die voldoen aan uw zoekterm.")
                        }
                        resultArray = result.map(element => new SearchResult(element));
                    }
                } else {
                    // Map data to a SearchResult object
                    resultArray.push(new SearchResult(result));
                }
                setSearchResult(resultArray);
                setErrorObject(null);
                setIsSearching(false);
            } catch(error) {
                setIsSearching(false);
                setErrorObject(error);
            }
        }

        performSearch();
    }, [searchObject]); // Only rerun when searchObject has changed

    return (
        <div className="flex flex-col w-full lg:mx-12">
            <SearchTabbar isSearching={isSearching} onTabClicked={onTabClicked} startSearch={startSearch} />
            {searchResultText === "" ? null : <SearchResultText text={searchResultText} />}
            {searchResult === null ? null : <SearchResultContainer searchResult={searchResult} />}
            {errorObject === null ? null : <ErrorComponent errorText={errorObject} />}
        </div>
    )
}

export default Search;
