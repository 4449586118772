import React, { useState, useEffect } from 'react'
import {statsTitlesGone} from 'components/services/statistics'
import AudioButton from 'components/common/AudioButton'
import DropdownOption from 'components/common/dropdown/DropdownOption.js';
import Dropdown       from 'components/common/dropdown/Dropdown.js';
import { artistImageUrl } from 'components/utils/helper'
import SongTitleGone from './models/SongTitleGone'

const statGoneArray = Array.from({length: 26}, (v, k) => String.fromCharCode(k + 65));

const StatGoneControls = ({selectedChar, handleCharSelect}) => {
  
    const numCharsHalf = Math.ceil(statGoneArray.length / 2);
    const statGoneArray1 = statGoneArray.slice(0, numCharsHalf);
    const statGoneArray2 = statGoneArray.slice(numCharsHalf);
    const controls = [statGoneArray1, statGoneArray2];

    return (
        <div className="w-full my-8">
        {controls.map(function(arr, index) {
            return (
                <div key={index} className="flex justify-between my-2">
                {arr.map(function(char, index) {
                    const styling = char === selectedChar ? "bg-orange-800 hover:bg-orange-400 text-gray-200" : "bg-gray-300 hover:bg-gray-600 text-gray-800";
                    return (
                        <button key={index} className={"w-12 h-12 border-4 border-black text-center font-bold items-center rounded " + styling} onClick={() => handleCharSelect(char)}>
                            {char}
                        </button>
                    )
                    }
                )}
                </div>
            )}
        )}
        </div>
    )
}

const StatPageSelector = ({total, selectedPage, numPages, handlePageSelect}) => {

    const pageOptions = Array.from({length: numPages}, (v, k) => new DropdownOption(k + 1, k + 1));
    
    return (
        <div className="flex justify-between items-center my-4">
            <div className="text-gray-100 text-md font-bold">Aantal verdwenen nummers: {total}</div>
            <Dropdown label='Pagina' options={pageOptions} selected={selectedPage} onSelectedChange={handlePageSelect} />
        </div>
    )
}

const StatSongTitleGone = ({songTitle}) => {
    return (
        <div className="flex h-24 mb-4 border border-black">
            <img className="flex-shrink-0 w-24 max-h-full ml-2 my-2 object-cover overflow-hidden" src={artistImageUrl(songTitle.photo)} alt={""} title={songTitle.artistName} />
            <div className="flex w-full justify-between items-center">
                <div className="flex-grow flex-col w-1/2 ml-4">
                    <p className="text-gray-100 text-lg italic truncate">{songTitle.titleName}</p>
                    <p className="text-gray-100 text-sm font-bold truncate">{songTitle.artistName}</p>
                </div>
                <div className="hidden md:block flex-col w-1/3">
                    <p className="text-gray-100 text-sm text-center truncate">Laatst genoteerd in</p>
                    <p className="text-gray-100 text-sm text-center font-bold">{songTitle.yearLastEdition}</p>
                </div>
                <div className="flex-shrink-0 w-1/6 mr-2">
                    <AudioButton fragment={songTitle.fragment} />
                </div>
            </div>
        </div>
    )
}

const StatSongTitlesContainer = ({songTitles, selectedChar, handleCharSelect, total, selectedPage, numPages, handlePageSelect}) => {
  
    return (
        <div className="flex-col w-full">
        <StatGoneControls selectedChar={selectedChar} handleCharSelect={handleCharSelect} />
        <StatPageSelector total={total} selectedPage={selectedPage} numPages={numPages} handlePageSelect={handlePageSelect} />
        {songTitles.map(function(songTitle, index) {
            return (
            <StatSongTitleGone key={index} songTitle={songTitle} />
            )
            })
        }
        </div>
    )
}

const StatsRecordsGone = () => {
    const [selectedChar, setSelectedChar] = useState(statGoneArray[Math.floor(Math.random() * statGoneArray.length)]);
    const [songTitles, setSongTitles] = useState(null);
    const [total, setTotal] = useState(0);
    const [page, setPage] = useState(1);
    const [numPages, setNumPages] = useState(null);
    const [errorMessage, setErrorMessage] = useState(null);

    const handleCharSelect = (statGoneSelectedChar) => {
        setSelectedChar(statGoneSelectedChar);
        setPage(1);
    }

    const handlePageSelect = (selectedPageDropDownOption) => {
        setPage(selectedPageDropDownOption.value);
    }
    
    useEffect(() => {
        async function fetchTitlesGone() {
            try {
                const result = await statsTitlesGone(selectedChar, page);
                
                // Map data to a SongTitleGone object
                const songTitles = result.songTitles.map(songTitle => new SongTitleGone(songTitle));
                setTotal(result.total);
                setNumPages(result.maxPage);
                setSongTitles(songTitles);
                
                //setIsLoading(false);
                
            } catch(error) {
                //setIsLoading(false);
                setErrorMessage("Something went wrong... apologies");
            }
        }

        fetchTitlesGone();
    }, [selectedChar, page]); // Only rerun when selectedChar or page has changed

    return (
        <div className="flex flex-col w-full">
        <h1 className="font-medium text-3xl text-center text-gray-100">Verdwenen titels</h1>
        {songTitles === null ? null : 
            <StatSongTitlesContainer
            songTitles={songTitles}
            selectedChar={selectedChar}
            handleCharSelect={handleCharSelect}
            total={total}
            selectedPage={page}
            numPages={numPages}
            handlePageSelect={handlePageSelect} />}
        </div>
    );
}

export default StatsRecordsGone;
