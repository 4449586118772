import React, {useState, useEffect} from 'react'
import useInterval from 'components/utils/useInterval.js'
import { getForgottenTitle } from 'components/services/getForgottenTitle'
import SongTitle from 'components/models/SongTitle'
import { playAudio } from 'components/utils/audio' // stopAudio should also be triggered
import { artistImageUrl } from 'components/utils/helper'

const Error = ({message}) => {
    return (
        <p className="font-bold text-red-800">{message}</p>
    )
}

const LoadingForgottenTitle = () => {
    return (
        <div className="flex justify-center">
            <p className="font-bold text-lg">Loading...</p>
        </div>
    );
}

const AudioButton = ({fragment}) => {

    function handleAudio() {
        playAudio(fragment, null);
    }

    const bgColor = fragment === null ? "bg-gray-400" : "bg-blue-400";
    const textColor = fragment === null ? "text-gray-200" : "text-white";
    const focusColor = fragment === null ? "" : " focus:bg-blue-600 outline-none";
    const stylingClasses = "w-full h-8 my-4 rounded-lg " + bgColor + " " + textColor + " " + focusColor;
    return (
        <button className={stylingClasses} onClick={() => handleAudio()}>Play</button>
    )
}

const ForgottenTitleComponent = ({songTitle}) => {
    var latestPosition = null;
    songTitle.positions.reverse().forEach(element => {
        if (element.position !== null && latestPosition === null) {
            latestPosition = element;
        }
    });
    const topPosition = songTitle.positions.filter(element => element.position === songTitle.topPosition)[0];

    return (
        <div className="flex flex-col justify-center bg-gray-200 rounded-lg p-2 mt-8">
            <div className="bg-white font-bold text-sm text-gray-800 p-2">Verdwenen plaat</div>      
            <img src={artistImageUrl(songTitle.photo)} className="w-full h-48 object-contain my-2" alt='' />
            <p className="font-bold text-sm italic">{songTitle.titleName + " (" + songTitle.year + ")"}</p>
            <p className="font-bold text-sm">{songTitle.artistName}</p>
            <AudioButton fragment={songTitle.fragment} />
        
            <p className="font-bold text-sm">Laatste notering: {latestPosition.position} in {latestPosition.year}</p>
            <p className="font-bold text-sm">Hoogste notering: {topPosition.position} in {topPosition.year}</p>
        
        </div>
    );
}

const ForgottenTitle = () => {

    const [songTitle, setSongTitle] = useState(null);
    const [errorMessage, setErrorMessage] = useState(null);

    async function fetchForgottenTitle() {
        try {
            const result = await getForgottenTitle();
            setSongTitle(new SongTitle(result));
            setErrorMessage(null);
        } catch(error) {
            setErrorMessage("Something went wrong... apologies");
        }
    }

    useEffect(() => {
        //fetchForgottenTitle();

        var timer = null;

        const setTimerForForgottenTitle = () => {
            if (timer !== null) {
                clearTimeout(timer);
            }
            timer = setTimeout(fetchForgottenTitle, 1500);
        }
        setTimerForForgottenTitle();
    }, []);

    useInterval(() => {
        fetchForgottenTitle()
    }, 18 * 1000);

    return (
        <div>
            {errorMessage != null ? <Error message={errorMessage} /> : false }
            {songTitle == null ? <LoadingForgottenTitle /> : <ForgottenTitleComponent songTitle={songTitle} /> }
        </div>
    );
}

export default ForgottenTitle;
