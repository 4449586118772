export class Decade {
  
    constructor(decadeId, description) {
        this.decadeId = decadeId;
        this.description = description;
    }

}

export const decadesArray = [
    new Decade(1, '1920 - 1929'),
    new Decade(2, '1930 - 1939'),
    new Decade(3, '1940 - 1949'),
    new Decade(4, '1950 - 1959'),
    new Decade(5, '1960 - 1969'),
    new Decade(6, '1970 - 1979'),
    new Decade(7, '1980 - 1989'),
    new Decade(8, '1990 - 1999'),
    new Decade(9, '2000 - 2009'),
    new Decade(10, '2010 - 2019'),
    new Decade(11, 'vanaf 2020')
];
