import React, { useState, useEffect, useCallback } from 'react';
import useInterval from 'components/utils/useInterval.js';
import { playAudio, stopAudio } from 'components/utils/audio.js';
import { getTitlesPerHour } from 'components/services/getTitlesPerHour'
import { ScrollableTitle } from './scrollableTitle.js';
import './scrollableTitles.css'

export default function ScrollableTitleContainer() {
    var today = new Date();
    
    const [currentHourOfDay] = useState(today.getHours());
    const [currentBroadcastingHour, setCurrentBroadcastingHour] = useState(null);
    const [songTitles, setSongTitles] = useState([]);
    const [selectedTitleId, setSelectedTitleId] = useState(0);
    const [errorMessage, setErrorMessage] = useState("");
    const [playingTitleId, setPlayingTitleId] = useState(null);

    function handleAudioClick(titleId) {
        if (playingTitleId === titleId) {
            stopAudio(handleAudioEnded);
            return;
        }
        let fragment = songTitles.find(e => e.titleId === titleId).fragment
        playAudio(fragment, handleAudioEnded);
        setPlayingTitleId(titleId);
    }

    function handleVideoClick(videoSearchArg) {
        if (window.confirm("Open YouTube in een apart tabblad?")) {
            window.open("https://www.youtube.com/results?search_query="+encodeURIComponent(videoSearchArg));
        }
    }

    function handleAudioEnded() {
        setPlayingTitleId(null);
    }

    function updateSelectedTitle(optionalTitles) {
        var theTitles = songTitles;
        if (optionalTitles != null) {
            theTitles = optionalTitles;
        }
        // First check whether we need to fetch new data
        var now = new Date();
        var currentHour = now.getHours();
        if (currentHour !== currentHourOfDay) {
            fetchTitlesPerHour();
            return;
        }

        if (theTitles.length === 0) {
            return;
        }

        var calculatedCurrentTitleIndex;

		// Retain a margin of 4 minutes before and 4 minutes after an exact hour
		if (now.getMinutes() >= 56) {
			calculatedCurrentTitleIndex = 0;
		}
		else if (now.getMinutes() < 4) {
			calculatedCurrentTitleIndex = theTitles.length - 1;
		}
		else {
            // Calculate seconds passed in the current hour
            const secondsPassedInCurrentHour = now.getMinutes() * 60 + now.getSeconds();
            calculatedCurrentTitleIndex = theTitles.length - Math.floor(secondsPassedInCurrentHour / ((3600 - (8 * 60)) / theTitles.length));
        }
        setSelectedTitleId(theTitles[calculatedCurrentTitleIndex].titleId);
	}

    async function fetchTitlesPerHour() {
        try {
            const result = await getTitlesPerHour();
            setCurrentBroadcastingHour(result.hour);
            setSongTitles(result.songTitles);
            updateSelectedTitle(result.songTitles);
        } catch(error) {
            setErrorMessage("Something went wrong... apologies");
        }
    }
    /*const fetchTitlesPerHour = useCallback(async () => {
        try {
            const result = await getTitlesPerHour();
            setCurrentBroadcastingHour(result.hour);
            setSongTitles(result.songTitles);
            updateSelectedTitle(result.songTitles);
        } catch(error) {
            setErrorMessage("Something went wrong... apologies");
        }
    }, [updateSelectedTitle]);*/

    useEffect(() => {
        fetchTitlesPerHour();
    }, []);

    useInterval(() => {
        updateSelectedTitle()
    }, 30 * 1000);

    if (errorMessage !== "") {
        return (
            <div><p>{errorMessage}</p></div>
        );
    } else {
        return (
            <div id="content">
                <div className="p-2 bg-white text-gray-800 uppercase font-bold text-lg">Uur nummer <span className="text-red-600">{currentBroadcastingHour}</span> van de Top 2000</div>
                <div id="main" className="clearfix" role="main">
                    {songTitles.map(function(songtitle, i) {
                        return <ScrollableTitle 
                            songtitle={songtitle}
                            key={songtitle.titleId}
                            isSelected={songtitle.titleId === selectedTitleId}
                            isPlaying={songtitle.titleId === playingTitleId}
                            onAudioClick={handleAudioClick}
                            onVideoClick={handleVideoClick} />;
                    })}
                </div>
            </div>
        );
    }
    
}
