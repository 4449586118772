import React, {useState} from 'react'
import {searchTabData} from './SearchTabData'
import SearchTypeArtist from './SearchTypeArtist'
import SearchTypeOrigin from './SearchTypeOrigin'
import SearchTypeTitle from './SearchTypeTitle'
import SearchTypeYear from './SearchTypeYear'

const SearchTabElement = ({searchTab, onTabSelected}) => {
    const stylingActive = "bg-white text-blue-800 border border-white border-b-0";
    const stylingInactive = "text-white hover:text-blue-600 mx-1";
    const defaultStyling = "inline-block py-2 px-4 font-semibold ";
    const resultStyling = defaultStyling + (searchTab.isSelected ? stylingActive : stylingInactive);
    
    return (
        <li className="p-0">
            <div className={resultStyling} onClick={() => onTabSelected(searchTab.index)}>{searchTab.title}</div>
        </li>
    )
}

const SmallSearchIndicator = ({isSearching}) => {
    return (
        <li>
            <div className={`rounded-full h-8 w-8 border-t-4 border-b-4 border-white rounded animate-spin ${isSearching ? "block" : "hidden"}`} />
        </li>
    )
}

const SearchTabbar = ({isSearching, onTabClicked, startSearch}) => {
    const [searchTabs, setSearchTabs] = useState(searchTabData);
    
    const onTabSelected = (selectedIndex) => {
        onTabClicked();
        const modifiedSearchTabs = searchTabs.map(function(element) {
            element.isSelected = element.index === selectedIndex;
            return element;
        });
        setSearchTabs(modifiedSearchTabs);
    }

    const handleStartSearch = (arg) => {
        // Get the searchType of the current selected tab
        const selectedTab = searchTabs.find(element => element.isSelected);
        startSearch(selectedTab.searchType, arg);
    }
  
    return (
        <div className="py-2">
            <ul className="flex">
                {searchTabs.map(element => <SearchTabElement key={element._uid} searchTab={element} onTabSelected={onTabSelected} />)}
                <SmallSearchIndicator isSearching={isSearching} />
            </ul>
            <div className="p-4 border border-gray-100">
                {searchTabs.filter(item => item.isSelected).map(function(searchTab, index) {
                switch (searchTab.component) {
                    case "searchTypeArtist": return (<SearchTypeArtist key={index} handleSelectedArist={handleStartSearch} />)
                    case "searchTypeTitle": return (<SearchTypeTitle key={index} handlePartialTitle={handleStartSearch} />)
                    case "searchTypeOrigin": return (<SearchTypeOrigin key={index} handleSelectedCountry={handleStartSearch} />)
                    case "searchTypeYear": return (<SearchTypeYear key={index} handleSelectedYear={handleStartSearch} />)
                    default:
                    return (<div>No selection</div>)
                }
                })}
            </div>
        </div>
    )
}

export default SearchTabbar;
