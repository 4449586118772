export const statisticTypesData = [
  {
    _uid: "j6QwdfJLKR",
    index: 0,
    component: "statsRecordsForDecades",
    title: "Aantal platen per decennium",
    subtitle: "Toon een grafiek van het aantal titels per jaar voor een geselecteerd uitzendjaar en een geselecteerd decennium.",
    isSelected: false,
  },
  {
    _uid: "M88XkEwQDI",
    index: 1,
    component: "statsRecordsNew",
    title: "Nieuwe platen in de lijst",
    subtitle: "Toon een overzicht van de platen die in een uitzendjaar nieuw in de lijst zijn gekomen.",
    isSelected: false,
  },
  {
    _uid: "6fREaXMj4k",
    index: 2,
    component: "statsRecordsGone",
    title: "Verdwenen platen",
    subtitle: "Een overzicht van platen, die niet meer in de lijst staan sinds de laatste uitzending.",
    isSelected: false,
  },
  {
    _uid: "38tN8DfwIg",
    index: 3,
    component: "statsRecordsOrigin",
    title: "Herkomst van platen",
    subtitle: "Geeft een grafiek van de herkomst van de platen per uitzendjaar.",
    isSelected: false,
  },
  {
    _uid: "jeN5DGvrHW",
    index: 4,
    component: "statsRecordsMovement",
    title: "Stijgers en dalers",
    subtitle: "Welke platen zijn gestegen of gezakt in de lijst?",
    isSelected: false,
  }
];
