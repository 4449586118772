import React, {useState, useEffect} from 'react'
import ErrorComponent from 'components/common/ErrorComponent'
import Dropdown, {DropdownWidth} from 'components/common/dropdown/Dropdown.js'
import DropdownOption from 'components/common/dropdown/DropdownOption.js'
import {getOrigins} from 'components/services/getOrigins'

const CountryDropDown = ({countries, selectedCountry, onCountryChange}) => {
    return (
        <div className="flex justify-between my-4">
            <Dropdown label='Herkomst' options={countries} dropdownWidth={DropdownWidth.LARGE} selected={selectedCountry} onSelectedChange={onCountryChange} />
        </div>
    )
}

const SearchTypeOrigin = ({handleSelectedCountry}) => {

    const [selectedCountry, setSelectedCountry] = useState(null);
    const [countries, setCountries] = useState([]);
    const [errorMessage, setErrorMessage] = useState(null);


    function onCountryChange(dropDownOption) {
        setSelectedCountry(dropDownOption.value);
        handleSelectedCountry(dropDownOption.value)
    }

    useEffect(() => {
        async function getCountries() {
            try {
                const result = await getOrigins()
                const dropDownItems = result.map(element => new DropdownOption(element.countryName, element.countryCode));
                setCountries(dropDownItems)
            } catch(error) {
                setErrorMessage("Something went wrong... apologies");
            }
        }
    
        getCountries()
    }, []); // Only rerun if ... has changed

  
    return (
        <div className="w-full">
            <h2 className="text-white font-medium">Selecteer een land uit de onderstaande lijst voor de artiesten uit dat land.</h2>
            {countries.length === 0 ? null : <CountryDropDown countries={countries} selectedCountry={selectedCountry} onCountryChange={onCountryChange} /> }
            {errorMessage === null ? null : <ErrorComponent errorText={errorMessage} />}
        </div>
    )
}

export default SearchTypeOrigin;
