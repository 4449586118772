import React, { useEffect, useState } from "react"
import { FaWindowClose } from "react-icons/fa";
import ErrorComponent from "components/common/ErrorComponent";
import LoadingIndicator from "components/common/LoadingIndicator";
import { getTitleDetails } from 'components/services/detailsservice';
import SongTitle from 'components/models/SongTitle';

// import is needed
import { Chart as ChartJS } from 'chart.js/auto'
import { Line }            from 'react-chartjs-2'

const LineChart = ({positionsData}) => {
    
    const labels = positionsData.map(function(element) { return String(element.year) })
    const data = positionsData.map(function(element) { 
        if (element.position !== null) {
            return (2000 - element.position)
        } else {
            return null }
        })
    
    const state = {
        labels: labels,
        datasets: [
          {
            fill: false,
            lineTension: 0.5,
            backgroundColor: 'rgba(75,192,192,1)',
            borderColor: 'rgba(0,0,0,1)',
            borderWidth: 2,
            data: data
          }
        ]
    }
    return (
        <div className="bg-gray-100 py-4">
          <Line
            data={state}
            options={{
                scales: {
                    y: { 
                        grid: {
                            display: true
                        },
                        ticks: {
                            display: true,
                            stepSize: 500,
                            callback: function(value, index, ticks) {
                                return value === 2000 ? String(1) : String(2000 - value);
                            }
                        },
                        min: 0,
                        max: 2000
                    }
                },
                title: {
                    display: false,
                },
                plugins: {
                    tooltip: {
                        callbacks: {
                            title: function(tooltipItem) {
                                // This is an array with 1 element
                                return "Positie " + tooltipItem[0].label;
                            },
                            label: function(tooltipItem) {
                                return String(2000 - tooltipItem.raw)
                            }
                        }
                    },
                    legend: { display: false }
                }
            }}
          />
        </div>
    )
}

const LineGraphModal = ({songtitle, onDismiss}) => {
    
    return (
        <>
            <div className="flex items-center justify-between">
                <p className="text-xl font-bold ml-4">{songtitle.artistName + " - " + songtitle.titleName}</p>
                <FaWindowClose className="w-8 h-8 m-2 text-red-600 cursor-pointer" onClick={() => onDismiss()}></FaWindowClose>
            </div>
            <div>

            </div>
            <div className="mx-8">
                <LineChart positionsData={songtitle.positions} />
            </div>
        </>
    )
}

// Overlay that covers the whole viewport (position: fixed)
const SongtitleGraphOverlay = ({titleId, isOverlayPresented, onDismiss}) => {
    const [isLoading, setIsLoading] = useState(false);
    const [errorMessage, setErrorMessage] = useState(null);
    const [songtitle, setSongtitle] = useState(null);
    
    useEffect(() => {
        async function getSongDetails() {
            if (titleId === null) {
                return;
            }
            try {
                setIsLoading(true);
                const result = await getTitleDetails(titleId);
                // Map data to a SongTitle object, containing properties 'position' and 'positionPreviousYear'.
                const songtitle = new SongTitle(result);
                setSongtitle(songtitle);
                setIsLoading(false);
            } catch(error) {
                setIsLoading(false);
                setErrorMessage("Something went wrong... apologies");
            }
        }

        getSongDetails()
    }, [titleId]);

    const overlayClasses = "fixed top-0 left-0 w-full h-full bg-black bg-opacity-50 " + (isOverlayPresented ? "" : "hidden");

    return (
        <div className={overlayClasses} onClick={() => onDismiss()}>
            <div className="relative top-1/2 left-1/2 mt-64 w-1/2 h-80 m-auto bg-white rounded-md">
                <LoadingIndicator isLoading={isLoading} />
                {errorMessage === null && songtitle !== null ? <LineGraphModal songtitle={songtitle} onDismiss={onDismiss} /> : null }
                {errorMessage !== null ? <ErrorComponent errorText={errorMessage} /> : false }
            </div>
        </div>
    )
}

export default SongtitleGraphOverlay;
