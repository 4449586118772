import React, {useState} from 'react'
import { ImStatsDots } from 'react-icons/im'
import LoadingIndicator from './LoadingIndicator.js'
import SongtitleGraphOverlay from './SongtitleGraphOverlay.js'
import { artistImageUrl } from 'components/utils/helper'

const ListPicture = ({image}) => {
    const styling = {backgroundImage : "url('" + artistImageUrl(image) + "')"};
    return (
        <div className="w-24 h-16 md:w-32 md:h-24 max-h-full bg-cover bg-center bg-no-repeat" style={styling} />
    );
}

const PositionDifference = ({songTitle}) => {

    const NewEntry = () => {
        const styling = {backgroundImage : "url('" + String(require("assets/images/new.png")) + "')"};
        return (
            <>
                <div className="mx-auto w-6 h-6 bg-cover bg-center bg-no-repeat" style={styling} />
            </>
        )
    }

    const ExistingEntry = ({songTitle}) => {
        const difference = parseInt(songTitle["positionPreviousYear"]) - parseInt(songTitle["position"]);
        var assetName = difference === 0 ? "minus.png" : (difference > 0 ? "arrow-up.png" : "arrow-down.png");
        const styling = {backgroundImage : "url('" + String(require("assets/images/"  + assetName)) + "')"};
        return (
            <>
                <div className="mx-auto w-4 h-4 bg-cover bg-center bg-no-repeat" style={styling} />
                {difference !== 0 ? <div className="text-center text-gray-500">{Math.abs(difference)}</div> : null }
            </>
        )
    }

    return (
        <div className="w-1/12 flex-col text-center">
          {songTitle["positionPreviousYear"] === null || isNaN(songTitle["positionPreviousYear"])
            ? <NewEntry />
            : <ExistingEntry songTitle={songTitle} />}
        </div>
    )
}

const ListRowComponent = ({songTitle, onStatsClicked}) => {
    
    var positionPrevYear = songTitle["positionPreviousYear"];
    if (songTitle["positionPreviousYear"] === null || isNaN(songTitle["positionPreviousYear"])) {
        positionPrevYear = "-";
    }

    return (
        <div className="flex w-full h-16 md:h-24 items-center bg-gray-800 rounded-md mb-2">
            <div className="w-12 md:w-16 font-bold text-lg md:text-xl text-center text-gray-300">{songTitle.position}</div>
            <ListPicture image={songTitle.photo} />
            <div className="flex-1 flex flex-col overflow-hidden">
                <div className="pl-4 text-left text-gray-300 text-sm md:text-lg italic truncate">{songTitle.titleName}</div>
                <div className="pl-4 text-left text-gray-500 text-sm md:text-lg truncate">{songTitle.artistName}</div>
            </div>
            <div className="hidden md:block w-1/12 text-center text-gray-300 text-lg">{songTitle["year"]}</div>
            <PositionDifference songTitle={songTitle} />
            
            <div className="hidden md:block w-1/12 flex flex-col">
                <div className="text-center text-gray-300">{positionPrevYear}</div>
                <div className="text-center text-gray-600 text-sm">Vorig jaar</div>
            </div>
            <div className="hidden md:block w-1/12 flex flex-col">
                <div className="text-center text-gray-300">{songTitle.topPosition}</div>
                <div className="text-center text-gray-600 text-sm">Top positie</div>
            </div>
            <div className="hidden md:block w-1/12 flex flex-col">
                <div className="text-center text-gray-300">{songTitle.numEditions}</div>
                <div className="text-center text-gray-600 text-sm"># edities</div>
            </div>
            <div className="hidden md:block w-12">
                <button className="text-gray-300 inline-flex items-center" onClick={() => onStatsClicked(songTitle.titleId)}>
                    <ImStatsDots className="w-6 h-6" />                    
                </button>
            </div>
        </div>
    )
}

const ListHeader = () => {
    return (
        <div className="flex w-full h-12 items-center">
            <div className="w-12 md:w-16 text-xs text-gray-600 text-center">Positie</div>
            <div className="w-24 md:w-32" />
            <div className="flex-1 text-xs text-gray-600 text-left pl-4">Titel &amp; artiest</div>
            <div className="w-1/12 text-xs text-gray-600 text-center hidden md:block">Jaar</div>
            <div className="w-1/12 text-xs text-gray-600 text-center hidden md:block">Verschuiving</div>
            <div className="w-1/12 text-xs text-gray-600 text-center hidden md:block">Vorig jaar</div>
            <div className="w-1/12 text-xs text-gray-600 text-center hidden md:block">Top positie</div>
            <div className="w-1/12 text-xs text-gray-600 text-center hidden md:block"># Edities</div>
            <div className="w-12 text-xs text-gray-600 text-left"></div>
        </div>
    )
}

const ListComponent = ({isLoading, songTitles}) => {

    const [titleId, setTitleId] = useState(null);
    const [isOverlayPresented, setIsOverlayPresented] = useState(false);

    const onStatsClicked = (titleId) => {
        setTitleId(titleId);
        setIsOverlayPresented(true);
    }

    function handleOverlayDismissed() {
        setIsOverlayPresented(false);
    }

    return (
        <div className="relative my-8">
            <SongtitleGraphOverlay titleId={titleId} isOverlayPresented={isOverlayPresented} onDismiss={handleOverlayDismissed} />
            <ListHeader />
            {songTitles.map(function(songTitle, i) {
                return (
                    <ListRowComponent key={i} songTitle={songTitle} onStatsClicked={onStatsClicked} /> 
                );
            })}
            <LoadingIndicator isLoading={isLoading} />
        </div>
    );
}

export default ListComponent;
